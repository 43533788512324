<template>
  <div class="internal-quotation-listing">
    <v-layout class="my-2">
      <v-flex md4>
        <v-text-field
          v-model.trim="search"
          v-on:click:clear="clearFilter('search')"
          clearable
          dense
          filled
          flat
          label="Search"
          hide-details
          solo
          :loading="quotationLoading"
          :disabled="quotationLoading"
          color="cyan"
          @keydown.enter="getProjects()"
          @keydown.tab="getProjects()"
        ></v-text-field>
      </v-flex>
      <v-flex md4>
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              tile
              depressed
              :disabled="quotationLoading"
              v-on:click="getProjects()"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>Search</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <ListingTable :columnCount="6" :rowData="rowData">
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th">Project #</th>
            <th class="simple-table-th">Project Details</th>
            <th class="simple-table-th">Amount</th>
            <th class="simple-table-th">Customer</th>
            <!-- <th class="simple-table-th">Site Location</th> -->
            <th class="simple-table-th">Milestones</th>
            <th class="simple-table-th">visit Count</th>
            <th class="simple-table-th">Status</th>
            <th class="simple-table-th">Created Time</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="rowData.length">
            <tr
              v-for="(data, index) in rowData"
              :key="index"
              v-on:click="routeToQuotation(data)"
            >
              <td class="simple-table-td">
                <Barcode :barcode="data.barcode"></Barcode>
              </td>
              <td class="simple-table-td">
                <div class="project-listing-project">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Name: </b> {{ data.name }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Reference #: </b>
                    <template v-if="data.reference">
                      {{ data.reference }}
                    </template>
                    <template v-else
                      ><em class="text--secondary">No Reference </em>
                    </template>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Start Date: </b>
                    {{ formatDate(data.started_at) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>End Date: </b> {{ formatDate(data.deadline) }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <div class="project-listing-project">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Project Value: </b>
                    <template v-if="data && data.project_value">
                      <span class="font-weight-700">{{
                        formatMoney(data.project_value)
                      }}</span></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Project Budget: </b>
                    <template v-if="data && data.project_budget"
                      ><span class="font-weight-700">{{
                        formatMoney(data.project_budget)
                      }}</span></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <div class="project-listing-project" v-if="data.customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Display Name: </b>
                    {{ data.customer && data.customer.display_name }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Company: </b>
                    <template
                      v-if="data.customer && data.customer.company_name"
                    >
                      {{ data.customer && data.customer.company_name }}
                    </template>
                    <em v-else class="text--secondary">no company</em>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b> Email : </b>
                    <template
                      v-if="data.customer && data.customer.company_email"
                    >
                      {{ data.customer.company_email }}</template
                    >
                    <em v-else class="text--secondary">no email</em>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Phone Number : </b>
                    <template
                      v-if="data.customer && data.customer.company_number"
                    >
                      {{ data.customer.company_number }}</template
                    >
                    <em v-else class="text--secondary">no phone</em>
                  </p>
                </div>
              </td>
              <!--   <td class="simple-table-td">
                <div class="project-listing-project">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Name: </b> {{ data.name }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Reference #: </b>
                    <template v-if="data.reference">
                      {{ data.reference }}
                    </template>
                    <template v-else
                      ><em class="text--secondary">No Reference </em>
                    </template>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Start Date: </b>
                    {{ formatDate(data.started_at) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>End Date: </b> {{ formatDate(data.deadline) }}
                  </p>

               
                </div>
              </td> -->
              <td class="simple-table-td">
                <div
                  class="project-listing-project"
                  v-if="data && data.milestone && data.milestone.length > 0"
                >
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Name: </b>
                    <template
                      v-if="
                        data &&
                        data.milestone &&
                        data.milestone[0] &&
                        data.milestone[0].name
                      "
                    >
                      {{ data.milestone[0].name }}
                    </template>
                    <template v-else
                      ><em class="text--secondary">No name </em>
                    </template>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Date : </b>
                    <template
                      v-if="
                        data &&
                        data.milestone &&
                        data.milestone[0] &&
                        data.milestone[0].date
                      "
                    >
                      {{
                        formatDate(
                          data &&
                            data.milestone &&
                            data.milestone[0] &&
                            data.milestone[0].date
                        )
                      }}
                    </template>
                    <template v-else
                      ><em class="text--secondary">No date </em>
                    </template>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Remnder: </b>
                    {{
                      formatDate(
                        data &&
                          data.milestone &&
                          data.milestone[0] &&
                          data.milestone[0].reminder_date
                      )
                    }}
                  </p>
                  <template
                    v-if="data && data.milestone && data.milestone.length > 1"
                  >
                    <v-chip small color="blue white--text" label>
                      <span class="font-size-16 font-weight-500">
                        +{{
                          data && data.milestone && data.milestone.length - 1
                        }}
                        More</span
                      >
                    </v-chip>
                  </template>
                </div>
                <em v-else class="text-muted"> no project milestone</em>
              </td>
              <td class="simple-table-td">
                <div
                  class="contract-listing-customer"
                  v-if="
                    data.opne_visit_count > 0 ||
                    data.overdue_visit_count > 0 ||
                    data.inprogress_visit_count > 0 ||
                    data.completed_visit_count > 0
                  "
                >
                  <p class="m-1 mb-1">
                    <v-badge
                      color="blue"
                      :content="data.opne_visit_count"
                      class="mr-9 ml-2"
                    >
                      <!--   Item Two -->
                    </v-badge>
                    <b>Opne </b>
                    <!-- <template v-if="data.total_service">{{
                            data.total_service
                            }}</template> -->
                  </p>

                  <p class="m-1 mb-1">
                    <v-badge
                      color="orange"
                      :content="data.overdue_visit_count"
                      class="mr-9 ml-2"
                    >
                    </v-badge>
                    <b>Overdue</b>
                  </p>
                  <p class="m-1 mb-1">
                    <v-badge
                      color="yellow"
                      class="mr-9 ml-2"
                      :content="data.inprogress_visit_count"
                    >
                    </v-badge>
                    <b>In-Progress </b>
                  </p>
                  <p class="m-1 mb-1">
                    <v-badge
                      color="green"
                      class="mr-9 ml-2"
                      :content="data.completed_visit_count"
                    >
                    </v-badge>
                    <b>Completed </b>
                  </p>
                </div>
                <em v-else class="text-muted"> no visit count</em>
              </td>
              <td class="simple-table-td">
                <div class="project-listing-status">
                  <CustomStatus
                    small
                    :status="data.status"
                    endpoint="project/status"
                  ></CustomStatus>
                </div>
              </td>
              <td class="simple-table-td">
                <TableActivity
                  v-if="!lodash.isEmpty(data.added_by)"
                  :data="data"
                >
                  <template v-slot:display_name>
                    {{ data.added_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ data.created_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(data.added_at) }}
                  </template>
                </TableActivity>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="9">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no project at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading="quotationLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
    ></ListingFooter>
  </div>
</template>

<script>
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import { QUERY } from "@/core/services/store/request.module";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      quotationLoading: false,
      rowData: [],
      currentPage: 1,
      status: "all",
      search: null,
      totalPages: 0,
      totalRows: 0,
      rowsOffset: 0,
    };
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    typeId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    typeId() {
      this.getProjects();
    },
  },
  methods: {
    clearFilter() {
      //
    },
    getProjects() {
      const data = {
        current_page: this.currentPage,
        search: this.search,
        status: this.status,
        entity: 0,
        type_id: this.typeId,
      };

      if (this.quotationLoading) {
        return false;
      }

      this.quotationLoading = true;

      this.$store
        .dispatch(QUERY, { url: "projects", data: data })
        .then(({ data }) => {
          this.totalPages = Math.ceil(data.total_rows / data.per_page);
          this.currentPage = data.current_page;
          this.rowData = data.rows;
          this.totalRows = data.total_rows;
          this.rowsOffset = data.offset;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.quotationLoading = false;
        });
    },
    routeToQuotation(row) {
      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("project.detail", {
            params: {
              id: row.id,
            },
          })
        );
      });
    },
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.rowData ? this.rowData.length : 0);
    },
  },
  mounted() {
    this.getProjects();
  },
  components: {
    Barcode,
    ListingFooter,
    ListingTable,
    TableActivity,
    CustomStatus,
  },
};
</script>
